<template>
    <el-dialog
            title="企微账号"
            v-if="wework.show"
            :visible.sync="wework.show"
            width="60%">
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-link" size="mini" @click="handleAdd">绑定</el-button>
                <!--https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=CorpApp&appid=wwd036d82f9879e492&redirect_uri=http%3A%2F%2Fzebj.kaoshizaixian.com&state=STATE&agentid=1000033-->
            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="企微名称" prop="name"></el-table-column>
            <el-table-column align="center" label="企微账号" prop="remarks"></el-table-column>
            <el-table-column align="center" label="企微部门" prop="createBy"></el-table-column>
            <el-table-column align="center" label="绑定时间" prop="createTime"></el-table-column>
            <el-table-column align="center" label="操作">
                <el-button type="text">解绑</el-button>
            </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="wework.show= false">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: "BindWework",
    props: {
        wework: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            loading: false,
            dataList: [{}]
        }
    },
    methods: {
        handleAdd() {
            let url = "https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=CorpApp&appid=wwd036d82f9879e492&redirect_uri=http%3A%2F%2Fzebj.kaoshizaixian.com/api/wework/sign&state=STATE&agentid=1000033"
            let screenWidth = window.screen.width;
            let screenHeight = window.screen.height;
            let width = (screenWidth - 200)/2
            let height = (screenHeight - 200)
            window.open(url, '_blank', `width=${width},height=${height},left=300,top=300`)
        },
    }
}
</script>

<style scoped>

</style>